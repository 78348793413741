<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <select v-model="search_data.hub_id" v-validate="{ required: true }" id="hub_id" name="hub_id" class="form-control form-control-sm">
                <option selected disabled :value="''">Select a hub</option>
                <option v-for="(hub, index) in hubs" :key="index" :value="hub.id">{{ hub.name }}</option>
              </select>
              <div class="invalid-feedback">Hub name is required</div>
            </div>
            <div class="col-md-4">
              <a-range-picker @change="onDateRangeChange" />
            </div>
            <div class="col-md-4">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-sm table-bordered text-center" id="purchase_order_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Hub</th>
              <th>Status</th>
              <th>Created At</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(requisition, i) in requisitions.data" :key="i">
              <td>{{ requisitions.from + i}}</td>
              <td>{{ requisition.hub.name }}</td>
              <td>
                <a-tag color="#f50" v-if="requisition.requisition_status.includes('ADMIN_PENDING') && !requisition.requisition_status.includes('ADMIN_APPROVED')">
                  Pending
                </a-tag>
                <a-tag color="#87d068" v-if="requisition.requisition_status.includes('ADMIN_PENDING') && requisition.requisition_status.includes('ADMIN_APPROVED')">
                  Approved
                </a-tag>
              </td>
              <td>{{ customDate(requisition.created_at) }}</td>
              <td>
                <div>
                  <a-dropdown placement="bottomRight" :trigger="['click']">
                    <button type="button" class="btn btn-outline-success">
                      <i class="fe fe-more-vertical" /> Download
                    </button>
                    <a-menu slot="overlay">
                      <a-menu-item>
                        <a-button class="btn btn-outline-success btn-block mr-2" :loading="pdfDownloadLoader && i === index" @click.prevent="pdfDownload(requisition.id, index = i)"><i class="fa fa-file-pdf-o mr-1"></i> PDF</a-button>
                      </a-menu-item>
                      <a-menu-item>
                        <a-button class="btn btn-outline-success btn-block mr-2" :loading="excelDownloadLoader && i === index" @click.prevent="excelDownload(requisition.id, index = i)"><i class="fa fa-file-excel-o mr-1"></i> Excel</a-button>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="requisitions.total === 0 || !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
          <div class="float-right">
            <pagination class="mt-2" :data="requisitions" :limit="2" @pagination-change-page="getResults"></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import apiClient from '@/services/axios'

export default {
  name: 'List',
  components: { Empty },
  data() {
    return {
      hubs: [],
      requisitions: {},
      index: -1,
      pdfDownloadLoader: false,
      excelDownloadLoader: false,
      loading: false,
      btnLoading: false,
      loader: false,
      flag: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      search_data: {
        hub_id: '',
        date_range: '',
      },
    }
  },
  mounted() {
    this.codes()
  },
  methods: {
    codes() {
      apiClient.get('api/user/codes')
        .then(response => {
          const data = response.data
          this.hubs = data.hubs
        })
        .catch(error => {
          console.log(error)
        })
    },
    onDateRangeChange(date, dateString) {
      this.search_data.date_range = dateString
    },
    customDate(date) {
      return moment(date).format('LL')
    },
    search() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.flag = true
          this.loading = true
          this.btnLoading = true
          apiClient.post('api/report/requisition/search', this.search_data)
            .then(response => {
              this.loading = false
              this.btnLoading = false
              this.flag = true
              this.requisitions = response.data.requisitions
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
    getResults(page = 1) {
      this.flag = true
      this.loading = true
      apiClient.post('api/report/requisition/search?page=' + page, this.search_data)
        .then(response => {
          this.loading = false
          this.flag = true
          this.requisitions = response.data.requisitions
        })
    },
    pdfDownload(requisitionId, index) {
      this.pdfDownloadLoader = true
      apiClient.get('api/report/individual-requisition/pdf-download/' + requisitionId, { responseType: 'blob' }).then(response => {
        this.pdfDownloadLoader = false
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'requisition.pdf')
        document.body.appendChild(link)
        link.click()
      }).catch(error => {
        this.pdfDownloadLoader = false
        console.log(error)
      })
    },
    excelDownload(requisitionId, index) {
      this.excelDownloadLoader = true
      apiClient.get('api/report/individual-requisition/excel-download/' + requisitionId, { responseType: 'blob' }).then(response => {
        this.excelDownloadLoader = false
        if (response.data.size === 68) {
          this.$notification.error({
            message: 'No data found, in this search query',
          })
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'hub_requisition.xlsx')
          document.body.appendChild(link)
          link.click()
        }
      }).catch(error => {
        this.excelDownloadLoader = false
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
</style>
